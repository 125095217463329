<template>
  <div class="remind">
    <div v-if="isLoading">
      <v-progress-circular
        :size="70"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <img
        alt="checker logo"
        src="../../assets/dementia/logo_3.svg"
        class="chapter-logo"
      />
      <p class="headline">質問は以上です。</p>
      <p class="headline">3ヶ月後にセルフチェックを受けますか？</p>
      <div class="white-box">
        <p class="text-block">
          定期的にセルフチェックを受けることで、現状を把握し予防につなげることができます。
        </p>
        <p class="text-block">
          AskDoctorsに登録済みのメールアドレスに、お知らせメールをお送りします。
        </p>
        <div v-if="userStatus === 'not_login'">
          <p class="bold-text">3ヶ月後にセルフチェックのお知らせを受け取る</p>
          <p class="text-block">
            ※AskDoctorsの無料会員登録またはログインが必要です。
          </p>

          <div class="button-block">
            <a
              v-bind:href="fetchFreeRegisterUrl"
              class="lineless"
              @click="
                requestRemindRequest();
                sendGa('click', 'remind', 'remind_registration');
              "
            >
              <p class="button-label activebutton">無料会員登録</p>
            </a>
            <a
              v-bind:href="fetchLoginUrl"
              class="lineless"
              @click="
                requestRemindRequest();
                sendGa('click', 'remind', 'remind_login');
              "
            >
              <p class="button-label white-button">会員の方はログイン</p>
            </a>
          </div>
        </div>
        <div v-else>
          <div class="button-block">
            <router-link
              to="/result"
              class="lineless"
              @click.native="
                sendGa('click', 'remind', 'remind_button');
                requestRemindRequest();
              "
            >
              <p class="button-label activebutton">
                セルフチェックの<br />お知らせを受け取る
              </p>
            </router-link>
          </div>
        </div>
        <br />
        <p>
          <router-link
            @click.native="sendGa('click', 'remind', 'remind_skip')"
            to="/result"
          >
            スキップして結果を見る</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import rootStore from "@/store";
export default {
  name: "Remind",
  head: {
    title: {
      inner: "認知症かんたんチェック"
    }
  },
  data: function() {
    return {
      isLoading: false
    };
  },
  computed: {
    fetchRedirectUrl() {
      return encodeURIComponent(
        process.env.VUE_APP_BASE_URL + "result" + location.search
      );
    },
    fetchFreeRegisterUrl() {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "user/pre-register/free?return_to=" +
        this.fetchRedirectUrl
      );
    },
    fetchLoginUrl() {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "login?return_to=" +
        this.fetchRedirectUrl
      );
    },
    userStatus() {
      return rootStore.state.dementia.userStatus;
    }
  },
  methods: {
    requestRemindRequest() {
      rootStore.commit("dementia/changeIsRequestRemind", { value: true });
    }
  },
  beforeCreate: function() {
    this.axios
      .get(process.env.VUE_APP_API_BASE_URL + "v2/api/self_checker/user_info", {
        withCredentials: true
      })
      .then(res => {
        this.$cookies.set("patient_id", res.data.patientId, "24h");
        rootStore.commit("dementia/changeUserInfo", {
          patientId: res.data.patientId,
          userStatus: res.data.userStatus
        });
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
        rootStore.commit("dementia/changeUserInfo", {
          patientId: "",
          userStatus: "not_login"
        });
        this.isLoading = false;
      });
  }
};
</script>

<style scoped>
@import "../../assets/style/dementia.css";
.headline {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  width: 280px;
  margin: 10px auto;
}

.next-button {
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 200px;
}
</style>
